import React from 'react'
import '../../styles/services.css'
const servicesData=[
    {
        icon: 'fas fa-mobile-alt',
        title: 'App Development',  
        des: 'We specialize in building high-performance mobile applications tailored to your business needs, ensuring a seamless user experience and robust functionality.'
      },
      {
        icon: 'fas fa-code',
        title: 'Web Development',  
        des: 'Our team delivers cutting-edge web development services, from dynamic websites to scalable web applications, ensuring performance, security, and responsiveness across all devices.'
      },
      {
        icon: 'fas fa-paint-brush',
        title: 'Graphics Design',  
        des: 'Our creative designers craft visually compelling graphics that effectively communicate your brand’s message, ensuring consistent, captivating designs across all media platforms.'
      },
      {
        icon: 'fas fa-bullhorn',
        title: 'Digital Marketing',  
        des: 'Our digital marketing strategies are designed to boost your online presence, drive targeted traffic, and increase conversions through SEO, social media, email campaigns, and paid advertising.'
      }
      
  ]
const Services = () => {
  return <section id="service">
    <div className="container">
        <div className="service__top-content">
            <h6 className='subtitle'>Our Services</h6>
            <h2>Save Time Managing Your Business with</h2>
            <h2 className='highlight'>with our best services</h2>
        </div>


        <div className="service__item-wrapper">
           {
            servicesData.map((item,index)=>(
                <div className="service__item" key={index}>
                    <span className="service__icon">
                        <i class={item.icon}></i>
                    </span>
                    <h3 className="service__title">
                        {item.title}
                    </h3>
                    <p className="description">{item.des}
                    </p>
                </div>
            ))
           }
        </div>
    </div>
  </section>
}

export default Services