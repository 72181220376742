import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/UI/Hero';
import Counter from './components/UI/Counter';
import Services from './components/UI/Services';
import About from './components/UI/About';
import Team from './components/UI/Team';
import Footer from './components/UI/Footer';
function App() {
  return(
     <>
        <Header/>
        <Hero/>
        {/* <Counter/> */}
        <Services/>
        <About/>
        <Team/>
        <Footer/>

    </>
  );
}

export default App;
