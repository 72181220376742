import React from 'react'
import '../../styles/hero.css'
import heroDarkImg from '../../images/Image-hero.png'

const Hero = () => {
  return(
    <section className='hero__section'>
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
              <div>
                  <h2>We're Crafting Perfect</h2>
                  <h2>Digital Experiences To</h2>
                  <h2 className="highlight">Elevate Your Brand</h2>
                </div>
                <p className="description">
                  Our team specializes in creating cutting-edge digital solutions that not only engage your audience but also boost your brand’s visibility. Whether it's innovative web design, mobile apps, or compelling digital marketing strategies, we tailor each solution to meet your unique business needs and help you stay ahead of the competition.
                </p>
            <div className="hero__btns">
                <button type="button" class="btn">
                      <strong>GET STARTED</strong>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                </button>

              <button className="secondary__btn">Discover More</button>
            </div>
          </div>
          <div className="hero__img">
              <img src={heroDarkImg} alt="hero-img" />
          </div>
        </div>
      </div>
    </section>
  )
  
}

export default Hero